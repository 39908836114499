import gql from "graphql-tag";

export const GET_CLIENT_INVOICE = gql`
query GetAllClientInvoice($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
  getAllClientInvoice(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        results
        count
        paid
        pending
        overdue
        draft
        cancelled
  }
}`;

export const CREATE_CLIENT_INVOICE = gql`
mutation CreateClientInvoice($data: InvoiceClientInput!, $fullName: String!, $companyName: String!) {
  createClientInvoice(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_CLIENT_INVOICE = gql`
mutation UpdateClientInvoice($_id: ID!, $data: InvoiceClientInput!, $fullName: String!, $companyName: String!) {
  updateClientInvoice( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_CLIENT_INVOICE= gql`
mutation DeleteClientCourse($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteClientInvoice(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;


export const UPDATE_CLIENT_RECEIPT = gql`
mutation UpdateClientReceipt($_id: ID!, $data: InvoiceClientInput!, $fullName: String!, $companyName: String!) {
  updateClientReceipt( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
    result
  }
}`;

